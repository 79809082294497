$(document).ready(function () {
  $('.hero').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
  });

  $('#carousel').slick({
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay:false,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }

    ]
  });



  $('.slick-control-prev').on('click', function(){
    $('#carousel').slick('slickPrev');
  });
  $('.slick-control-next').on('click', function(){
    $('#carousel').slick('slickNext');
  });

});
